import React from "react";
import useRequestCount from "../hooks/useRequestCount";

export default function DashboardMessage({ tenantId, locationId }) {
  const { requestCounts } = useRequestCount();

  return (
    <div className="row mt-4">
      <div className="col-lg-7 mb-lg-0 mb-4">
        <div className="card">
          <div className="card-body p-3">
            <div className="row">
              <div className="col-lg-6">
                <div className="d-flex flex-column h-100">
                  <p className="mb-1 pt-2 text-bold text-primary">
                    Welcome {localStorage.getItem("firstName")}{" "}
                    {localStorage.getItem("lastName")}!{" "}
                    <span role="img" aria-label="Welcome">
                      🎉
                    </span>
                  </p>
                  <h5 className="font-weight-bolder">Service Requests</h5>
                  <p className="mb-4">
                    There are{" "}
                    <span className="fw-bold">
                      {requestCounts.pendingRequests}
                    </span>{" "}
                    new service requests. Check all the service requests in
                    "Service Request" menu.
                  </p>
                  <a
                    className="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
                    href={`#/t/${tenantId}/l/${locationId}/service-requests`}
                  >
                    View All
                    <i
                      className="fas fa-arrow-right text-sm ms-1"
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </div>
              <div className="col-lg-5 ms-auto text-center mt-5 mt-lg-0">
                <div className="bg-gradient-primary border-radius-lg h-100">
                  <img
                    src="../assets/img/shapes/waves-white.svg"
                    className="position-absolute h-100 w-50 top-0 d-lg-block d-none"
                    alt="waves"
                  />
                  <div className="position-relative d-flex align-items-center justify-content-center h-100">
                    <img
                      className="w-100 position-relative z-index-2 pt-4"
                      src="../assets/img/illustrations/rocket-white.png"
                      alt="rocket"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-5">
        <div className="card h-100 p-3">
          <div className="overflow-hidden position-relative border-radius-lg bg-cover h-100">
            <span className="mask bg-gradient-dark"></span>
            <div className="card-body position-relative z-index-1 d-flex flex-column h-100 p-3">
              <h5 className="text-white font-weight-bolder mb-4 pt-2">
                Promote your services
              </h5>
              <p className="text-white">
                Boost your business by promoting your new services. Reach out to
                your customers.
              </p>
              <div className="text-white text-sm font-weight-bold mb-0 icon-move-right mt-auto">
                Promote
                <i
                  className="fas fa-arrow-right text-sm ms-1"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
