import React, { useContext, useEffect, useState } from "react";
import ConfirmPopupModal from "../popups/confirmationPopup";
import { useNavigate } from "react-router";
import { AppState } from "../state";

export default function Header() {
  const { appData, setAppData } = useContext(AppState);
  const [message, setMessage] = useState([]);
  useEffect(() => {
    if (appData !== undefined) {
      if (appData.length > 0) {
        setMessage((prevNotifications) => [...prevNotifications, appData]);
      }
    }

    // eslint-disable-next-line
  }, [appData]);

  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };
  const handleContext = () => {
    setAppData();
    setMessage([]);
  };
  const handleSidebar = () => {
    document.querySelector(".g-sidenav-show").classList.add("g-sidenav-pinned");
  };

  return (
    <nav
      className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
      id="navbarBlur"
      navbar-scroll="true"
    >
      <div
        className="container-fluid py-1 px-3 d-flex justify-content-center
      "
      >
        <div className="ms-md-auto pe-md-3 d-flex align-items-center">
          {/* <div className="input-group">
            <span className="input-group-text text-body">
              <i className="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Type here..."
            />
          </div> */}
        </div>
        <div
          className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
          id="navbar"
        >
          <div className="ms-md-auto pe-md-3 d-flex align-items-center"></div>
          <ul className="navbar-nav  justify-content-end">
            <li className="nav-item d-flex align-items-center">
              <p className="px-3">{localStorage.getItem("departmentName")}</p>
            </li>
            <li className="nav-item d-flex align-items-center">
              <div className="nav-link text-body font-weight-bold px-0 cursor-pointer">
                <div className="dropstart">
                  <i
                    className="fa fa-user me-sm-1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></i>
                  <ul className="dropdown-menu">
                    <li
                      data-bs-toggle="modal"
                      data-bs-target="#ConfirmModal"
                      className="ps-3 d-flex justify-between align-items-center"
                    >
                      <p>Logout</p>
                      <i className="fa fa-sign-out cursor-pointer pe-3"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
              <div
                className="nav-link text-body p-0"
                onClick={() => handleSidebar()}
                id="iconNavbarSidenav"
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                </div>
              </div>
            </li>
            <li className="nav-item px-3 d-flex align-items-center">
              <div className="nav-link text-body p-0">
                <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
              </div>
            </li>
            <li className="nav-item dropdown pe-2 d-flex align-items-center">
              <div
                className="nav-link text-body p-0"
                id="dropdownMenuButton"
                aria-expanded="false"
                data-bs-toggle="dropdown"
              >
                {message && message.length > 0 ? (
                  <div className="notification-icon">
                    <i className="fas fa-bell cursor-pointer"></i>
                    <div className="notification-dot"></div>
                  </div>
                ) : (
                  <i className="fa fa-bell cursor-pointer"></i>
                )}
              </div>
              <ul
                className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4 h-25vh overflow-auto"
                aria-labelledby="dropdownMenuButton"
              >
                {message && message.length > 0 ? (
                  message.map((item, index) => (
                    <li key={index} className="mb-2" onClick={handleContext}>
                      <a
                        className="dropdown-item border-radius-md"
                        href={`#/t/${localStorage.getItem(
                          "tenantId"
                        )}/l/${localStorage.getItem(
                          "locationId"
                        )}/service-requests`}
                      >
                        <div className="d-flex py-1">
                          <div className="my-auto">
                            {/* <img
                            src="../assets/img/team-2.jpg"
                            className="avatar avatar-sm  me-3 "
                            alt="team"
                          /> */}
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="text-sm font-weight-normal mb-1">
                              <span className="font-weight-bold">
                                {item[0].title}
                              </span>{" "}
                            </h6>
                            <p className="text-xs text-secondary mb-0 ">
                              {item[0].body
                                ? item[0].body.substring(0, 30) + "..."
                                : `New request for ${item[0].title} `}
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                  ))
                ) : (
                  <div className="text-center">No Notifications</div>
                )}
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <ConfirmPopupModal
        header={"Are you sure you want to Logout ?"}
        id={"ConfirmModal"}
        confirmButtonLabel={"Logout"}
        confirmOnclick={logout}
      ></ConfirmPopupModal>{" "}
    </nav>
  );
}
