import React from "react";
export const toaster = (status, msg, body) => {
  document.getElementById("liveToast").classList.replace("hide", "show");
  document.getElementById("toast-msg-title").innerHTML = msg;

  if (body !== undefined && body !== null) {
    document.getElementById("toast-msg-body").innerHTML = `${body.substring(
      0,
      30
    )}...`;
  } else {
    document.getElementById(
      "toast-msg-body"
    ).innerHTML = `New request for ${msg}`;
  }

  if (status === "success") {
    document.getElementById("toast-img");
    document.getElementById("toast-msg-title").style.color = "green";
  }
  if (status === "error") {
    document.getElementById("toast-img");
    document.getElementById("toast-msg-body").style.color = "red";
  }
};
export default function Toast() {
  return (
    <a
      href={`#/t/${localStorage.getItem("tenantId")}/l/${localStorage.getItem(
        "locationId"
      )}/service-requests`}
    >
      <div className="position-fixed top-0  start-100  p-3 alert-msg z-index-1212 firebase-bg toast-shadow">
        <div
          id="liveToast"
          className="toast hide w-auto px-4 firebase-bg text-nowrap "
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header flex-column firebase-bg">
            <strong className="me-auto" id="toast-msg-title"></strong>
            <strong className="me-auto" id="toast-msg-body"></strong>
          </div>
        </div>
      </div>
    </a>
  );
}
