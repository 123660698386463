import React from "react";
import Card from "../components/cards/card";
import Layout from "../components/layout/layout";
import ServiceRequestsSection from "../components/sections/service-requests-section";

export default function ServiceRequests({ alert }) {
  return (
    <Layout>
      <Card
        title="Service Requests"
        subTitle="Monitor service requests."
        showAddButton={false}
      >
        <ServiceRequestsSection alert={alert} />
      </Card>
    </Layout>
  );
}
