import React from "react";

export default function SelectGroup({ className, enabled = true, label, name, value, type, onChange, placeHolder, list, valueProperty, showLabel = true, labelProperty = "name"}) {
    return (
        <div className={className + " edc-input-group"} onClick={(event) => event.stopPropagation()}>
            {showLabel &&
                <label className="form-label">{label}</label>
            }
            <select onClick={(event) => event.stopPropagation()} className="form-control text-input" disabled={!enabled} name={name} onChange={(event) => onChange(event)} type={type} value={value} placeholder={placeHolder}>
                <option value="undefined">Select</option>
                {list && list.map((item) => <option key={item[valueProperty]} value={item[valueProperty]}>{item[labelProperty]}</option>)}
            </select>
        </div>
    );
}
