import React, { useContext, useEffect, useState } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import ServiceRequests from "./pages/ServiceRequests";
import ServiceRequestDetail from "./pages/ServiceRequestDetail";
import Toast, {
  toaster,
} from "./components/notification-popup/notification-popup";
import { getFirebaseToken, onForegroundMessage } from "./firebase";
import RequireAuth from "./utils/requireAuth";
import { AppState } from "./components/state";

export const handleGetFirebaseToken = async () => {
  return getFirebaseToken()
    .then((firebaseToken) => {
      localStorage.setItem("notifyToken", firebaseToken);
      return firebaseToken;
    })
    .catch((err) =>
      console.error("An error occurred while retrieving Firebase token. ", err)
    );
};

function App() {
  const { setAppData } = useContext(AppState);
  const [notificationPermission, setNotificationPermission] = useState(
    Notification.permission
  );
  // eslint-disable-next-line
  const [notifications, setNotifications] = useState([]);
  // const [notificationCount, setNotificationCount] = useState(false);
  const [alert, setAlert] = useState(false);
  useEffect(() => {
    if (Notification.permission !== "granted") {
      requestNotificationPermission();
    } else if (Notification.permission === "granted") {
      handleGetFirebaseToken();
    }
  }, [notificationPermission]);
  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      setNotificationPermission(permission);
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  };
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .catch((error) => {
          console.error("Error registering service worker:", error);
        });
    }
    navigator.serviceWorker.addEventListener("message", handleMessage);

    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   data.notification = notifications;
  //   // eslint-disable-next-line
  // }, [notifications]);

  const handleMessage = (event) => {
    if (event.data && event.data.type === "background-message") {
      // const { title, body, data } = event.data;
      // const newNotification = { title, body, data };
      // setNotifications((prevNotifications) => [
      //   ...prevNotifications,
      //   newNotification,
      // ]);
    }
  };

  setTimeout(function () {
    document.getElementById("liveToast").classList.replace("show", "hide");
  }, 3000);
  useEffect(() => {
    setAppData(notifications);
    // eslint-disable-next-line
  }, [notifications]);
  useEffect(() => {
    try {
      onForegroundMessage((payload) => {
        const {
          notification: { title, body },
          data,
        } = payload;
        const newNotification = { title, body, data };
        // setNotifications((prevNotifications) => [
        //   ...prevNotifications,
        //   newNotification,
        // ]);
        if (data.departmentId === localStorage.getItem("departmentId")) {
          setNotifications([newNotification]);
          toaster("success", title, body);
          setAlert(true);
        }
      });
    } catch (err) {
      console.log(
        "An error occurred while setting up foreground message listener. ",
        err
      );
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, 500);
  }, [alert]);
  return (
    <>
      <Toast />
      <HashRouter>
        <Routes>
          <Route element={<Login />} path="/" />
          <Route
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId"
          />

          <Route
            element={
              <RequireAuth>
                <ServiceRequests alert={alert} />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/service-requests"
          />

          <Route
            element={
              <RequireAuth>
                <ServiceRequestDetail />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/sr/:id"
          />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
