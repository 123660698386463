import { useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  getDepartment,
  login,
  postNotificationToken,
  // postNotificationToken,
} from "../service/LoginAPI";

import { useForm } from "react-hook-form";
import { handleGetFirebaseToken } from "../App";

export default function useLogin() {
  const navigate = useNavigate();
  const methods = useForm();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiInProgress, setApiInProgress] = useState(false);

  const [notifyToken, setNotifyToken] = useState({});

  const onSubmit = methods.handleSubmit((data) => {
    doLogin(data);
  });

  const onInputChange = (event) => {
    if (showErrorMessage) {
      setShowErrorMessage(false);
    }
  };

  const handleDepartmentResponse = async (response) => {
    if (response) {
      const data = response;
      localStorage.setItem("tenantId", data.tenantId);
      localStorage.setItem("locationId", data.locationId);
      localStorage.setItem("departmentId", data.department.id);
      localStorage.setItem("departmentName", data.department.name);
      navigate(`/t/${data.tenantId}/l/${data.locationId}`);
      await handleGetFirebaseToken();
      notifyToken.userId = localStorage.getItem("userId");
      notifyToken.departmentId = data.department.id;
      notifyToken.token = localStorage.getItem("notifyToken");
      notifyToken.deviceId = "";
      notifyToken.role = "ADMIN";

      postNotificationToken(notifyToken);
    }
  };

  const handleLoginResponse = async (response) => {
    if (response) {
      if (response.status === 200) {
        const data = response.data;
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("firstName", data.firstName);
        localStorage.setItem("lastName", data.lastName);
        localStorage.setItem("userId", data.id);
        localStorage.setItem("email", data.email);

        if (data.access_token) {
          getDepartmentDetails(data.id);
        }
      } else if (response.status === 401) {
        setErrorMessage("Invalid credentials.");
        setShowErrorMessage(true);
      }
    } else {
      setErrorMessage("Something went wrong. Try again later.");
      setShowErrorMessage(true);
    }
  };

  const doLogin = async (data) => {
    setApiInProgress(true);
    const formData = new FormData();
    formData.append("grant_type", "password");
    formData.append("username", data.email.toLowerCase());
    formData.append("password", data.password);
    localStorage.clear();
    const response = await login(formData);
    await handleLoginResponse(response);
    setApiInProgress(false);
  };

  const getDepartmentDetails = async (id) => {
    const response = await getDepartment(id);
    handleDepartmentResponse(response);
  };
  return {
    onSubmit,
    methods,
    errorMessage,
    showErrorMessage,
    onInputChange,
    apiInProgress,
    setNotifyToken,
  };
}
