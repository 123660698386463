import React from "react";
import ServiceRequestDetailSection from "../components/sections/service-request-detail-section";
import Layout from "../components/layout/layout";
import Card from "../components/cards/card";

export default function ServiceRequestDetail() {
  return (
    <Layout>
      <Card title="Service Request" showAddButton={false}>
        <ServiceRequestDetailSection />
      </Card>
    </Layout>
  );
}
