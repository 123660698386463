import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import VDiv from "../containers/vdiv";
import {
  // getServiceRequests,
  assign,
  getServiceRequestStatuses,
  updateStatus,
  getServiceRequestsByDepartmentId,
} from "../../service/ServiceRequestService";
import { getStaffs } from "../../service/StaffService";
import Loader from "../general/loader";
import HDiv from "../containers/hdiv";
import Popover from "../popups/popover";
import SelectGroup from "../inputs/select-group";
import { getFormattedDateAndTime } from "../../utils/formatters";
import ProcessLoader from "../general/process-loader";
import { getServiceRequestDetaillUrl } from "../../utils/Constant";
import Avatar from "../general/avatar";
import DataTable from "react-data-table-component";
export default function ServiceRequestsSection({ alert }) {
  const { tenantId, locationId } = useParams();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [staffs, setStaffs] = useState([]);
  const [showAssignForm, setShowAssignForm] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();
  let [statuses, setStatuses] = useState([]);
  const [statusUpdate, setStatusUpdate] = useState(false);

  const [apiInProgress, setApiInProgress] = useState(false);
  const departmentId = localStorage.getItem("departmentId");
  const navigate = useNavigate();
  const fetchServiceRequests = async () => {
    setLoading(true);
    const response = await getServiceRequestsByDepartmentId(
      tenantId,
      locationId,
      departmentId
    );
    setList(response);
    setLoading(false);
  };
  const fetchServiceRequestStatuses = async () => {
    const response = await getServiceRequestStatuses(tenantId, locationId);
    setStatuses(response);
  };
  const goToDetail = (item) => {
    navigate(getServiceRequestDetaillUrl(tenantId, locationId, item.id));
  };
  const fetchStaffs = async () => {
    const response = await getStaffs(tenantId, locationId);

    setStaffs(response);
  };
  const onCancel = () => {
    setShowAssignForm(false);
  };
  const onClose = () => {
    setShowAssignForm(false);
  };
  const onAssign = (event, item) => {
    event.stopPropagation();
    setSelectedRequest(item);
    setShowAssignForm(true);
  };
  const assignStaff = async (item) => {
    setApiInProgress(true);
    const data = {
      id: selectedRequest.id,
      assignedTo: { id: item.id, user: { id: item.user.id } },
      status: { name: "ASSIGNED" },
      assignedBy: { id: localStorage.getItem("userId") },
      service: { name: selectedRequest.service.name },
      comment: selectedRequest.comment === null ? "" : selectedRequest.comment,
    };
    await assign(tenantId, locationId, data);
    setApiInProgress(false);
    setShowAssignForm(false);
    fetchServiceRequests();
  };
  useEffect(() => {
    fetchServiceRequests();
    fetchStaffs();
    fetchServiceRequestStatuses();
  }, [statusUpdate, alert]);

  return (
    <VDiv>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ServiceRequest
            onAssign={onAssign}
            onClick={goToDetail}
            statuses={statuses}
            list={list}
            statusUpdate={statusUpdate}
            setStatusUpdate={setStatusUpdate}
          />
          <HDiv>
            {showAssignForm && (
              <Popover
                title={"Select a staff"}
                onCancel={onCancel}
                onClose={onClose}
                saveLabel="Assign"
              >
                <div className="table-responsive text-nowrap h100 w-100">
                  <table className="table striped">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Name</th>
                        <th>Department</th>
                        <th>Services</th>
                        <th>Availability</th>
                        <th>Assign</th>
                      </tr>
                    </thead>
                    <tbody>
                      {staffs.map(
                        (item, index) =>
                          item.department &&
                          item.department.id === departmentId && (
                            <tr key={item.id} className="v-align-center">
                              <td>{index + 1}</td>
                              <td>
                                {item.user.firstName} {item.user.lastName}
                              </td>
                              <td>
                                {item.department ? item.department.name : ""}
                              </td>
                              <td></td>
                              <td>Available</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-primary w-auto mb-0"
                                  onClick={() => assignStaff(item)}
                                >
                                  Assign
                                </button>
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
                {apiInProgress && <ProcessLoader />}
              </Popover>
            )}
          </HDiv>
        </>
      )}
    </VDiv>
  );
}
function ServiceRequest({
  data,
  onClick,
  onAssign,
  no,
  statuses,
  list,
  statusUpdate,
  setStatusUpdate,
}) {
  const [item, setItem] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("");
  const { tenantId, locationId } = useParams();

  const onStatusChange = async (event, row) => {
    setSelectedStatus(event.target.value);
    let status = statuses.filter((item) => {
      return item.id === event.target.value;
    });
    if (status.length > 0) {
      setItem({ ...item, status: status[0] });
      if (status[0].name === "ASSIGNED") {
        onAssign(event, item);
      } else {
        const data = {
          id: row.id,
          status: { id: event.target.value },
          doneBy: { id: localStorage.getItem("userId") },
        };
        await updateStatus(tenantId, locationId, data);
        setStatusUpdate(!statusUpdate);
      }
    }
  };
  const columns = [
    {
      name: "S. No",
      cell: (row, index) => (
        <span className={`${row.deleted ? "text-danger" : ""}`}>
          {" "}
          {index + 1}
          {setSelectedStatus(row.status.id)}
        </span>
      ),
      width: "80px",
      center: "true",
    },
    {
      name: "Service",
      selector: (row) => row.name,
      sortable: `${true}`,
      width: "300px",
      cell: (row) => (
        <span className="avatar-container" onClick={() => onClick(row)}>
          <Avatar name={row.service.name} />
          <strong>{row.service.name}</strong>
          {setItem(row)}
        </span>
      ),
    },
    {
      name: "Request Form",
      selector: (row) => (row.room !== null ? row.room.name : ""),
      // sortable: true,
      width: "200px",
    },
    {
      name: "Request On",
      selector: (row) => getFormattedDateAndTime(new Date(row.createdOn)),
      // sortable: true,
      width: "200px",
    },
    {
      name: "Assigned To",
      selector: (row) =>
        row.assignedTo ? (
          row.assignedTo.user.firstName
        ) : (
          <button
            type="button"
            className="btn btn-primary w-auto mb-0"
            onClick={(event) => onAssign(event, row)}
          >
            Assign
          </button>
        ),
      // sortable: true,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => (
        <div>
          <SelectGroup
            className={"w-100"}
            labelProperty="displayName"
            showLabel={false}
            list={statuses}
            valueProperty={"id"}
            label={"Select status"}
            value={selectedStatus === "" ? selectedStatus : row.status.id}
            onChange={(event) => onStatusChange(event, row)}
          />
        </div>
      ),
      width: "200px",
      // sortable: true,
    },
  ];
  return (
    <div>
      <DataTable
        data={list}
        className="dataTable"
        onRowClicked={onClick}
        columns={columns}
        pagination
      />
    </div>
  );
}
