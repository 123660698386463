import React from "react";
import useMenu from "../../hooks/useMenu";
import MenuItem from "./menu-item";

export default function Menu() {
  const handleSidebar = () => {
    document
      .querySelector(".g-sidenav-show")
      .classList.remove("g-sidenav-pinned");
  };
  const { items } = useMenu();
  return (
    <aside
      className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-white"
      id="sidenav-main"
    >
      <div className="d-flex">
        <div className="sidenav-header">
          <i
            className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
            aria-hidden="true"
            id="iconSidenav"
          ></i>
          <a className="navbar-brand m-0" href="/" target="_blank">
            <img
              src="../assets/images/gj-health-logo.png"
              alt="Sree Gokulam Hospital"
              height="40px"
              className="navbar-brand-img h-100"
            />
            <span className="ms-1 font-weight-bold">GJ Concierge</span>
          </a>
        </div>
        <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
          <div
            // href="index.html"
            className="nav-link text-body p-0"
            id="iconNavbarSidenav"
            onClick={() => handleSidebar()}
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line"></i>
              <i className="sidenav-toggler-line"></i>
              <i className="sidenav-toggler-line"></i>
            </div>
          </div>
        </li>
      </div>

      <div
        className="collapse navbar-collapse  w-auto"
        id="sidenav-collapse-main"
      >
        <ul className="navbar-nav">
          {items.map((item, index) => {
            return <MenuItem key={`menu_item_${index}`} data={item} />;
          })}
        </ul>
      </div>
    </aside>
  );
}
