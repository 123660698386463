import React from "react";
import Layout from "../components/layout/layout";

import ServiceRequestList from "../components/service-requests-list";
import DashboardMessage from "../components/dashboard-message";
import RequestCounts from "../components/request-counts";
import useDashboard from "../hooks/useDashboard";

export default function Dashboard() {
  const { tenantId, locationId } = useDashboard();
  return (
    <Layout>
      <RequestCounts />
      <DashboardMessage tenantId={tenantId} locationId={locationId} />
      <ServiceRequestList />
    </Layout>
  );
}
