import React from "react";
import useRequestCount from "../hooks/useRequestCount";

export default function RequestCounts() {
  const { requestCounts } = useRequestCount();
  return (
    <div className="row">
      <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <div className="card">
          <div className="card-body p-3">
            <div className="row">
              <div className="col-8">
                <div className="numbers">
                  <p className="text-sm mb-0 text-capitalize font-weight-bold">
                    Pending Requests
                  </p>
                  <h5 className="font-weight-bolder mb-0">
                    {requestCounts.pendingRequests}
                    {/* <span className="text-success text-sm font-weight-bolder"> +55%</span> */}
                  </h5>
                </div>
              </div>
              <div className="col-4 text-end">
                <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                  <i
                    className="ni ni-money-coins text-lg opacity-10"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <div className="card">
          <div className="card-body p-3">
            <div className="row">
              <div className="col-8">
                <div className="numbers">
                  <p className="text-sm mb-0 text-capitalize font-weight-bold">
                    Inprogress Requests
                  </p>
                  <h5 className="font-weight-bolder mb-0">
                    {requestCounts.inprogressRequests}
                    {/* <span className="text-success text-sm font-weight-bolder"> +3%</span> */}
                  </h5>
                </div>
              </div>
              <div className="col-4 text-end">
                <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                  <i
                    className="ni ni-world text-lg opacity-10"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <div className="card">
          <div className="card-body p-3">
            <div className="row">
              <div className="col-8">
                <div className="numbers">
                  <p className="text-sm mb-0 text-capitalize font-weight-bold">
                    Completed Requests
                  </p>
                  <h5 className="font-weight-bolder mb-0">
                    {requestCounts.completedRequests}{" "}
                    {/* <span className="text-danger text-sm font-weight-bolder"> -2%</span> */}
                  </h5>
                </div>
              </div>
              <div className="col-4 text-end">
                <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                  <i
                    className="ni ni-paper-diploma text-lg opacity-10"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6">
        <div className="card">
          <div className="card-body p-3">
            <div className="row">
              <div className="col-8">
                <div className="numbers">
                  <p className="text-sm mb-0 text-capitalize font-weight-bold">
                    Total Requests
                  </p>
                  <h5 className="font-weight-bolder mb-0">
                    {requestCounts.totalRequests}{" "}
                    {/* <span className="text-success text-sm font-weight-bolder"> +5%</span> */}
                  </h5>
                </div>
              </div>
              <div className="col-4 text-end">
                <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                  <i
                    className="ni ni-cart text-lg opacity-10"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
