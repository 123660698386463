import React from "react";
// import ServiceRequests from "../pages/ServiceRequests";
import ServiceRequestsSection from "./sections/service-requests-section";

export default function ServiceRequestList() {
  return (
    <div className="row my-4">
      <div className="col-12 mb-md-0 mb-4">
        <div className="card w-100">
          <div className="card-header d-flex w-100 pb-0">
            {/* <div className="row row-cols-2"> */}
            <div className="col-6">
              <h6>Service Requests</h6>
              {/* <p className="text-sm mb-0">
                <i className="fa fa-check text-info" aria-hidden="true"></i>
                <span className="font-weight-bold ms-1">30 done</span> this
                month
              </p> */}
            </div>
            {/* <div className="col  my-auto text-end">
              <div className="dropdown float-lg-end pe-4">
                <a
                  className="cursor-pointer"
                  id="dropdownTable"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  href="index.html"
                >
                  <i className="fa fa-ellipsis-v text-secondary"></i>
                </a>
                <ul
                  className="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                  aria-labelledby="dropdownTable"
                >
                  <li>
                    <a
                      className="dropdown-item border-radius-md"
                      href="index.html"
                    >
                      Action
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item border-radius-md"
                      href="index.html"
                    >
                      Another action
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item border-radius-md"
                      href="index.html"
                    >
                      Something else here
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
            {/* </div> */}
          </div>
          <div className="card-body px-0 pb-2">
            <ServiceRequestsSection />
          </div>
        </div>
      </div>
      {/* <div className="col-lg-4 col-md-6">
        <div className="card h-100">
          <div className="card-header pb-0">
            <h6>Requests overview</h6>
            <p className="text-sm">
              <i className="fa fa-arrow-up text-success" aria-hidden="true"></i>
              <span className="font-weight-bold">24%</span> this month
            </p>
          </div>
          <div className="card-body p-3">
            <div className="timeline timeline-one-side">
              <div className="timeline-block mb-3">
                <span className="timeline-step">
                  <i className="ni ni-bell-55 text-success text-gradient"></i>
                </span>
                <div className="timeline-content">
                  <h6 className="text-dark text-sm font-weight-bold mb-0">
                    100, Electronics
                  </h6>
                  <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                    22 DEC 7:20 PM
                  </p>
                </div>
              </div>
              <div className="timeline-block mb-3">
                <span className="timeline-step">
                  <i className="ni ni-html5 text-danger text-gradient"></i>
                </span>
                <div className="timeline-content">
                  <h6 className="text-dark text-sm font-weight-bold mb-0">
                    200, Food
                  </h6>
                  <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                    21 DEC 11 PM
                  </p>
                </div>
              </div>
              <div className="timeline-block mb-3">
                <span className="timeline-step">
                  <i className="ni ni-cart text-info text-gradient"></i>
                </span>
                <div className="timeline-content">
                  <h6 className="text-dark text-sm font-weight-bold mb-0">
                    100, Plumbing
                  </h6>
                  <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                    21 DEC 9:34 PM
                  </p>
                </div>
              </div>
              <div className="timeline-block mb-3">
                <span className="timeline-step">
                  <i className="ni ni-credit-card text-warning text-gradient"></i>
                </span>
                <div className="timeline-content">
                  <h6 className="text-dark text-sm font-weight-bold mb-0">
                    50, Spa
                  </h6>
                  <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                    20 DEC 2:20 AM
                  </p>
                </div>
              </div>
              <div className="timeline-block mb-3">
                <span className="timeline-step">
                  <i className="ni ni-key-25 text-primary text-gradient"></i>
                </span>
                <div className="timeline-content">
                  <h6 className="text-dark text-sm font-weight-bold mb-0">
                    34, House keeping
                  </h6>
                  <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                    18 DEC 4:54 AM
                  </p>
                </div>
              </div>
              <div className="timeline-block">
                <span className="timeline-step">
                  <i className="ni ni-money-coins text-dark text-gradient"></i>
                </span>
                <div className="timeline-content">
                  <h6 className="text-dark text-sm font-weight-bold mb-0">
                    20, Self assistant
                  </h6>
                  <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                    17 DEC
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
