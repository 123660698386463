import {
  getStaffsApiUrl,
  //   getSaveStaffApiUrl,
  getStaffApiUrl,
  getStaffServicesApiUrl,
  getSaveStaffServicesApiUrl,
  getDepartmentStaffsApiUrl,
  //   getDeleteStaffApiUrl,
  getUserExistApiUrl,
} from "./ApiUrls";
import { post, get } from "./Service";

export const getStaffs = async (tenantId, locationId) => {
  const response = await get(getStaffsApiUrl(tenantId, locationId));
  if (!response) {
    return [];
  }
  return response;
};

export const getUserExist = async (email) => {
  const response = await get(getUserExistApiUrl(email));
  if (!response) {
    return [];
  }
  return response;
};

export const getStaff = async (tenantId, locationId, staffId) => {
  const response = await get(getStaffApiUrl(tenantId, locationId, staffId));
  if (!response) {
    return [];
  }
  return response;
};

export const saveStaffServices = async (
  tenantId,
  locationId,
  staffId,
  data
) => {
  const response = await post(
    getSaveStaffServicesApiUrl(tenantId, locationId, staffId),
    data
  );
  return response;
};

export const getStaffServices = async (tenantId, locationId, staffId) => {
  const response = await get(
    getStaffServicesApiUrl(tenantId, locationId, staffId)
  );
  if (!response) {
    return [];
  }
  return response;
};

export const getDepartmentStaffs = async (
  tenantId,
  locationId,
  departmentId
) => {
  const response = await get(
    getDepartmentStaffsApiUrl(tenantId, locationId, departmentId)
  );
  return response;
};
