import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getServiceRequestCount } from "../service/ServiceRequestService";

export default function useRequestCount() {
  const { tenantId, locationId } = useParams();
  const departmentId = localStorage.getItem("departmentId");
  const [requestCounts, setRequestCounts] = useState({
    pendingRequests: "",
    inprogressRequests: "",
    completedRequests: "",
    totalRequests: "",
  });
  const getRequestCounts = async () => {
    const response = await getServiceRequestCount(
      tenantId,
      locationId,
      departmentId
    );
    setRequestCounts((prev) => ({
      ...prev,
      pendingRequests: response.newCount,
      inprogressRequests: response.inProgressCount,
      completedRequests: response.completedCount,
      totalRequests: response.totalCount,
    }));
  };
  useEffect(() => {
    getRequestCounts();
  }, []);
  return {
    requestCounts,
  };
}
