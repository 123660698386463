import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

export default function useMenu() {
  const [items, setItems] = useState([]);

  const { tenantId, locationId } = useParams();

  const generateMenus = (masterTypes) => {
    let items = [];
    items.push({
      title: "Dashboard",
      link: `#/t/${tenantId}/l/${locationId}`,
      icon: "bx-home-circle",
      type: "menu",
      active: true,
    });
    items.push({
      title: "Service Requests",
      link: `#/t/${tenantId}/l/${locationId}/service-requests`,
      icon: "bxs-cog",
      type: "menu",
    });
    // items.push({title: "Masters", type: "menu-header"})
    // items.push({title: "Departments", link: `#/t/${tenantId}/l/${locationId}/departments`, icon: "bxs-cog", type: "menu"})
    // items.push({title: "Categories", link: `#/t/${tenantId}/l/${locationId}/categories`, icon: "bx-category", type: "menu"})
    // items.push({title: "Blocks", link: `#/t/${tenantId}/l/${locationId}/blocks`, icon: "bx-category", type: "menu"})
    // items.push({title: "Services", link: `#/t/${tenantId}/l/${locationId}/services`, icon: "bx-cog", type: "menu"})
    // items.push({title: "Staffs", link: `#/t/${tenantId}/l/${locationId}/staffs`, icon: "bx-user", type: "menu"})
    // items.push({title: "Products", link: `#/t/${tenantId}/l/${locationId}/products`, icon: "bx-cog", type: "menu"})

    setItems(items);
  };
  useEffect(() => {
    generateMenus();
  }, []);

  return { items };
}
