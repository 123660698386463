import React from "react";

function MenuLink({data}) {
    return(
        <li className="nav-item">
          <a href={data.link} className={`nav-link ${data.active ? 'active' : ''}`}>
            <i className={`icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center tf-icons bx ${data.icon}`}></i>
            <span className="nav-link-text ms-1">{data.title}</span>
          </a>
        </li>
    )
}

function MenuHeader({data}) {
    return(
        <li className="nav-item">
          <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">{data.title}</h6>
        </li>
    )
}

export default function MenuItem({data}) {
    return(
        <>{data.type === 'menu' ? <MenuLink data={data} /> : <MenuHeader data={data} />}</>
    )
}